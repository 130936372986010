import React, { useEffect, useState } from 'react'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import PeopleIcon from '@mui/icons-material/People';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PaidIcon from '@mui/icons-material/Paid';
import { Button, CircularProgress, LinearProgress, Paper } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';


import doctorImg from "../../../images/doctorVector.avif"
import { MyResponsiveBar } from './BarChart';
import axios from 'axios';
import { url } from '../../apiConfig';
const DoctorDashboard = () => {

  const navigate = useNavigate();

  const greetMessage = () => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
  
    let greeting;
  
    if (currentHour >= 5 && currentHour < 12) {
      greeting = "Good morning!";
    } else if (currentHour >= 12 && currentHour < 18) {
      greeting = "Good afternoon!";
    } else {
      greeting = "Good evening!";
    }
  
    return greeting;
  };

  function todayformatDate(date) {
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns 0-11
    let year = date.getFullYear().toString().slice(-2);

    return `${day}-${month}-${year}`;
  }
  function tomorrowFormatDate() {
    let date = new Date(); // Gets today's date
    date.setDate(date.getDate() + 1); // Adds one day

    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns 0-11
    let year = date.getFullYear().toString().slice(-2);

    return `${day}-${month}-${year}`;
  }

  const date = new Date();
  const formattedDate = todayformatDate(date);
  const tomorrowFormattedDate = tomorrowFormatDate(date);
  const [isLoading,setIsLoading] = useState(true);
  const [dashBoardData,setDashBoardData] = useState();


  const rows = [
    { name: 'John Doe', mode: 'Online', slot: '9:00 AM' },
    { name: 'Jane Smith', mode: 'Offline', slot: '10:00 AM' },
    { name: 'Alice Johnson', mode: 'Online', slot: '11:00 AM' }
  ];


  // fetching appointment details
  const [TodayAppointments, setTodayAppointments] = useState([]);
  const [TomorrowAppointments, setTomorrowAppointments] = useState([]);
   


  function getTodayFullDate() {
      const date = new Date();
      const onlyDate = date.getDate();
      const onlyMonth = date.getMonth() + 1;
      const onlyYear = date.getFullYear();

      return (onlyDate + "-" + onlyMonth + "-" + onlyYear)

  }

  function getTomorrowFullDate() {
    const date = new Date();
    date.setDate(date.getDate() + 1); // Add one day to the current date

    const tomorrowDate = date.getDate();
    const tomorrowMonth = date.getMonth() + 1; // Month starts from 0
    const tomorrowYear = date.getFullYear();

    return tomorrowDate + "-" + tomorrowMonth + "-" + tomorrowYear;
}


  useEffect(() => {
    const todayDate = getTodayFullDate();
    const tomorrowDate = getTomorrowFullDate();

    const  fetchDoctorData = async ()=>{
      try{
        const response = await axios.get(url+`/fetchDoctorData?email=${localStorage.getItem("email")}`);
        setDashBoardData(()=>response.data.msg);
       
       
      }catch(e){
        console.log(`${e}`);
      }
      }

      async function getTodayAppointments() {
          const date = new Date();
          try {
              const { data: { msg: { appointedSlot } } } = await axios.get(url+ `/getTodayAppointments?date=${todayDate}&email=${localStorage.getItem("email")}`)
              console.log(appointedSlot);
              setTodayAppointments(appointedSlot)
          }
          catch (e) {
              console.log(`${e}`);
          }
      }

      const getTomorrowAppointments = async ()=>{

        try{
          const { data: { msg: { appointedSlot } } } = await axios.get(url+ `/getTomorrowAppointments?date=${tomorrowDate}&email=${localStorage.getItem("email")}`)
          setTomorrowAppointments(appointedSlot);
        }catch(e){

        }
      }

      fetchDoctorData();
      getTodayAppointments();
      getTomorrowAppointments();
     
      setIsLoading(false)
  }, [])



  return (


isLoading? <LinearProgress color='inherit'/> : 

    dashBoardData && <div className='h-full bg-[#F8F8FF]'>

      <div className='flex flex-row'>


        {/* col 1 */}
        <div className='flex p-8 gap-10 flex-col  basis-2/3 ]'>

          {/* greeting card */}
          <div className='px-8 py-8 flex  shadow-lg  justify-between flex-row gap-2 bg-white text-black text-left rounded-lg'>
            <div className='flex flex-col gap-2'>
              <h1 className='md:text-4xl font-poppins'>{greetMessage()}</h1>
              <p className='md:text-xl font-bold font-roboto'>{dashBoardData.name}</p>
              <p>Have a nice day at work.</p>
            </div>

            <div>
              {/* image */}
              <img src={doctorImg} style={{
                maxWidth: "200px",
                height: "auto"
              }} alt="" />
            </div>
          </div>

          {/* weekly reports */}

          <div className='flex gap-5 text-left flex-col'>
            <h1 className='text-2xl font-poppins font-medium'>Weekly Report</h1>

            <div className='flex flex-row gap-4 justify-around'>

              {/* card  */}
              <div className='py-5 px-10 flex flex-col gap-2 justify-center items-center shadow-lg bg-white rounded-lg text-center'>
                <PersonAddIcon fontSize='large' />
                <p className='font-poppins'>Total Patients</p>
                <p className='font-poppins text-2xl font-medium'>{dashBoardData.totalAppointments}</p>
              </div>

              {/* card  */}
              <div className='py-5 px-10 flex flex-col gap-2 justify-center items-center shadow-lg bg-white rounded-lg text-center'>
                <PeopleIcon fontSize='large' />
                <p className='font-poppins'>Offline Appointments</p>
                <p className='font-poppins text-2xl font-medium'>{dashBoardData.offlineAppointments}</p>
              </div>

              {/* card  */}
              <div className='py-5  px-10 flex flex-col gap-2 justify-center items-center shadow-lg bg-white rounded-lg text-center'>
                <VideoChatIcon fontSize='large' />
                <p className='font-poppins'>Online Appointments</p>
                <p className='font-poppins text-2xl font-medium'>{dashBoardData.onlineAppointments}</p>
              </div>

              {/* card  */}
              <div className='py-5 px-10 flex flex-col gap-2 justify-center items-center shadow-lg bg-white rounded-lg text-center'>
                <PaidIcon fontSize='large' />
                <p className='font-poppins'>Revenue Generated</p>
                <p className='font-poppins text-2xl font-medium'>{dashBoardData.revenue}</p>
              </div>

            </div>

          </div>

          {/* graph */}


          <div className='flex gap-5 h-96 shadow-2xl rounded-lg px-5 py-5 text-left flex-col'>
            <h1 className='text-2xl font-poppins font-medium'>No. Of Patients</h1>

          
            <MyResponsiveBar />

          </div>

        </div>

        {/* col 2 */}
        <div className='flex p-8 flex-col gap-10 basis-1/3 shadow-2xl '>

          {/* today appointments section */}
          <div className='flex flex-col gap-5'>
            <div className='flex flex-row justify-between'>
              <h1 className='text-xl font-poppins font-medium'>Today Appointments</h1>
              <p className='font-medium'>{formattedDate}</p>
            </div>

            {/* today appointment table */}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 200 }} aria-label="simple table">
                <TableHead >
                  <TableRow>
                    <TableCell align="left">Patient Name</TableCell>
                    <TableCell align="left">Mode</TableCell>
                    <TableCell align="left">Slot</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TodayAppointments && TodayAppointments.slice(0,3).map((row, index) => (
                    <TableRow
                      key={index} // Changed to index to avoid key duplication issue
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="left">
                        {row.patientName}
                      </TableCell>
                      <TableCell align="left">Offline</TableCell>
                      <TableCell align="left">{row.slot}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Link to={"/admin/appointments"}>
              <Button variant='contained' fullWidth disableElevation size='small'>View All</Button>
            </Link>
          </div>

          <hr style={
            {
              color: "black",
              backgroundColor: "black",
              borderColor: "black"
            }
          } />
          {/* tomorrow appointments section */}
          <div className='flex flex-col gap-5'>
            <div className='flex flex-row justify-between'>
              <h1 className='text-xl font-poppins font-medium'>Tomorrow Appointments</h1>
              <p className='font-medium'>{tomorrowFormattedDate}</p>
            </div>

            {/* today appointment table */}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 200 }} aria-label="simple table">
                <TableHead >
                  <TableRow>
                    <TableCell align="left">Patient Name</TableCell>
                    <TableCell align="left">Mode</TableCell>
                    <TableCell align="left">Slot</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TomorrowAppointments.slice(0,3).map((row, index) => (
                    <TableRow
                      key={index} // Changed to index to avoid key duplication issue
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="left">
                        {row.patientName}
                      </TableCell>
                      <TableCell align="left">Offline</TableCell>
                      <TableCell align="left">{row.slot}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Link to={"/admin/appointments"}>
              <Button variant='contained' fullWidth disableElevation size='small'>View All</Button>
            </Link>
          </div>
        </div>

      </div>

    </div>

  )
}

export default DoctorDashboard