import { Avatar, IconButton, Rating } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import { url } from "../apiConfig";
import { useNavigate } from "react-router-dom";
const CommentCard = ({ props }) => {

    const navigate= useNavigate();

    const deleteComment = async ()=>{
        try{
            const response = await axios.post(url + "/deleteComment",{
                name:props.name,
                phoneNumber:props.phoneNumber,
                comment:{
                    message:props.message,
                    author:props.author,
                    date:props.date,
                  
                }
            })
            window.location.reload();


                // navigate("/doctor",{
                //     state:props.state
                // })
        }catch(e){
            window.alert("Internal Server Error, Try Again")
            console.log(e);
        }
    }

    return (
        <div className="comment-card m-4 p-6  rounded-md cursor-pointer ">

            <div className="header-area mb-4 flex flex-row justify-between">
                <div className="avatar area flex gap-3  justify-center flex-row">
                    <Avatar >{props.author.slice(0, 1).toUpperCase()}</Avatar>


                    <div>
                        <p className="font-roboto text-sm">{props.author}</p>
                        <p className="font-roboto text-left text-xs py-1 font-light">{props.date}</p>
                    </div>
                </div>

            </div>

            <div className="flex-row flex justify-between">
                <div className="text-[#737373] font-roboto text-justify mb-5">{props.message}</div>
                { localStorage.getItem("username")==props.author &&  <IconButton onClick={deleteComment}>
                    <DeleteIcon/>
                </IconButton>}
            </div>
            <hr />
        </div>
    )

}


export default CommentCard;