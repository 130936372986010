import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { url } from '../../apiConfig';
import { LinearProgress } from '@mui/material';

const PatientRecords = () => {



  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        const response = await axios.get(url + `/fetchRecord?email=${localStorage.getItem("email")}`)
        setRows(() => response.data.records);

      } catch (e) {
        console.log("Internal Server Error", e);
      }

    }
    fetchPatientData();
    setIsLoading(false);
  }, [])


  rows.length != 0 && rows.map(row => {
    // Split the date string by "-"
    var parts = row && row.date.split("-");

    // Extract day, month, and year from parts array
    var day = parseInt(parts[0]);
    var month = parseInt(parts[1]) - 1; // Months in JavaScript are 0-indexed

    // Extract the last two digits of the year
    var yearPart = parseInt(parts[2]);

    // Determine the century based on the current year
    var currentYear = new Date().getFullYear();
    var century = Math.floor(currentYear / 100) * 100;

    // Determine the full year based on the last two digits
    var year = century + yearPart;

    // Convert the string to a Date object
    var dateObject = new Date(year, month, day);

    row.date = dateObject;
  })

  let rowsWithDays;
  if (rows) {
    rowsWithDays = rows.map(row => {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const dayOfWeek = days[row.date.getDay()];

      return { ...row, day: dayOfWeek };
    });
  }


  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 250,
    },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      width: 90,
    },

    {
      field: 'gender',
      headerName: 'Gender',
      width: 90,
    },

    {
      field: 'patientPhone',
      headerName: 'Patient Contact',
      sortable:false,
      width:200,
    },

    {
      field: 'date',
      headerName: 'Date',
      type: 'date',
      width: 120,
      sortable: true,
    },
    {
      field: 'day',
      headerName: 'Day',

    },
    {
      field: 'mode',
      headerName: 'Mode',
      width: 120,
    },
  ];






  return (
    <div>

      <h1 className='text-3xl   m-4 bg-white p-2 py-4 rounded-lg uppercase shadow-lg font-bold'>Patient Record</h1>


      {isLoading ? <LinearProgress /> : rows.length == 0 ? <h1 className='bg-white m-4 p-2 first-letter:'>Not Enough Data</h1> :

            <div className='p-4 ' style={{ height: '400', width: '100%' }}>
          <DataGrid
            getRowId={(row) => row.fullName + row.age}

            sx={{
              padding: "10px",
              backgroundColor: "white"
            }}
            rows={rowsWithDays}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        </div>
      }
    </div>
  )
}

export default PatientRecords