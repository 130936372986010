export const patientData = [

    { day: 'Monday', male: 20, female: 30 },
    { day: 'Tuesday', male: 22, female: 32 },
    { day: 'Wednesday', male: 25, female: 27 },
    { day: 'Thursday', male: 30, female: 35 },
    { day: 'Friday', male: 28, female: 40 },
    { day: 'Saturday', male: 35, female: 42 },
    { day: 'Sunday', male: 40, female: 37 }
];
