    // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC1hnrWDwdj88yEHFMFF-j1a7-jTu3Q5u0",
  authDomain: "bookwellcareprod.firebaseapp.com",
  projectId: "bookwellcareprod",
  storageBucket: "bookwellcareprod.appspot.com",
  messagingSenderId: "809208132499",
  appId: "1:809208132499:web:2188c1b5c6bbaaf0d1ae66",
  measurementId: "G-ZVR86E67H7"
};

// Initialize Firebase
const firebaseAuth = initializeApp(firebaseConfig);
const  auth = getAuth(firebaseAuth)

export default auth;