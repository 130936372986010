import axios from "axios";
import { useEffect, useState } from "react";
import {AppointmentCard} from "./AppointmentCard"
import { url } from "../../apiConfig";
import { LinearProgress } from "@mui/material";

export const AppointmentsSection = () => {


    const [TomorrowAppointments, setTomorrowAppointments] = useState([]);
    const [TodayAppointments, setTodayAppointments] = useState([]);
    const [isLoading,setLoading] = useState(true);
   
    const tomorrowDate = getTomorrowFullDate();
    function getTomorrowFullDate() {
        const date = new Date();
        date.setDate(date.getDate() + 1); // Add one day to the current date
    
        const tomorrowDate = date.getDate();
        const tomorrowMonth = date.getMonth() + 1; // Month starts from 0
        const tomorrowYear = date.getFullYear();
    
        return tomorrowDate + "-" + tomorrowMonth + "-" + tomorrowYear;
    }

    function getTodayFullDate() {
        const date = new Date();
        const onlyDate = date.getDate();
        const onlyMonth = date.getMonth() + 1;
        const onlyYear = date.getFullYear();

        return (onlyDate + "-" + onlyMonth + "-" + onlyYear)

    }


    useEffect(() => {
        const todayDate = getTodayFullDate();


        async function getTodayAppointments() {
            const date = new Date();
            try {
                const { data: { msg: { appointedSlot } } } = await axios.get(url+ `/getTodayAppointments?date=${todayDate}&email=${localStorage.getItem("email")}`)
                setTodayAppointments(appointedSlot)
            }
            catch (e) {
                console.log(`${e}`);
            }
        }


        const getTomorrowAppointments = async ()=>{

            try{
              const { data: { msg: { appointedSlot } } } = await axios.get(url+ `/getTomorrowAppointments?date=${tomorrowDate}&email=${localStorage.getItem("email")}`)
              setTomorrowAppointments(appointedSlot);
            }catch(e){
    
            }
          }




        getTomorrowAppointments();
        getTodayAppointments()
        
        setLoading(false)







    }, [])



    return (
        <div className="h-full">
            <h1 className='text-3xl   m-4 bg-white p-2 py-4 rounded-lg uppercase shadow-lg font-bold'>Today Appointments</h1>

           { isLoading ? <LinearProgress color="inherit"/> : TodayAppointments.length!=0? 
           
           <div className='grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 '>


                {TodayAppointments.map((slot) => <AppointmentCard key={Math.random()} props={slot} />)}

            </div>:<h1 className="text-2xl m-4  rounded-lg">No Appointments for Today Yet !</h1>}

            <h1 className='text-3xl m-4 bg-white p-2 py-4 rounded-lg uppercase shadow-lg font-bold'>Tomorrow Appointments</h1>
            
           { isLoading ? <LinearProgress color="inherit"/> : TomorrowAppointments.length!=0? 
           
           <div className='grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 '>


                {TomorrowAppointments.map((slot) => <AppointmentCard key={Math.random()} props={slot} />)}

            </div>:<h1 className="text-2xl m-4  rounded-lg">No Appointments for Tomorrow Yet !</h1>}


        </div>
    )
}