import React, { useState } from 'react'
import { Drawer, List, ListItem, ListItemText, Typography, IconButton } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

const DoctorPanel = () => {
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const handleToggleSidebar = () => {
        setOpen(!open);
    };

    return (
        <div>

            <Drawer
                anchor="left"
                open={open}
                onClose={handleToggleSidebar}
                sx={{
                    width: '200px',
                    flexShrink: 0,
                }}
            >
                <List>
                    <ListItem >
                        <h1 className='text-2xl font-bold p-4 uppercase'>Doctor Panel</h1>
                    </ListItem>

                    <ListItem button>

                        <ListItemText

                            onClick={() => {
                                navigate("dashboard");
                                handleToggleSidebar()
                            }}

                            sx={{ backgroundColor: "#3B82F6", color: "white", padding: "15px", borderRadius: "10px", fontWeight: "bold" }} primary="Dashboard" />
                    </ListItem>

                    <ListItem button>

                        <ListItemText

                            onClick={() => {
                                navigate("appointments");
                                handleToggleSidebar()
                            }}

                            sx={{ backgroundColor: "#3B82F6", color: "white", padding: "15px", borderRadius: "10px", fontWeight: "bold" }} primary="Appointments" />
                    </ListItem>

                    <ListItem button>

                        <ListItemText

                            onClick={() => {
                                navigate("records");
                                handleToggleSidebar()
                            }}

                            sx={{ backgroundColor: "#3B82F6", color: "white", padding: "15px", borderRadius: "10px", fontWeight: "bold" }} primary="Records" />
                    </ListItem>

                    <ListItem button>
                        <ListItemText

                            onClick={() => {
                                navigate("profile");
                                handleToggleSidebar()
                            }}

                            sx={{ backgroundColor: "#3B82F6", color: "white", padding: "15px", borderRadius: "10px", fontWeight: "bold" }} primary="Profile" />
                    </ListItem>
                    <ListItem onClick={() => {
                        localStorage.removeItem("email");
                        navigate("/doctorLogin")
                    }} button>
                        <ListItemText sx={{ color: "red", padding: "15px", borderRadius: "10px" }} primary="Logout" />
                    </ListItem>
                </List>
            </Drawer>

            <header className="bg-blue-500 p-4 text-white flex justify-start items-center">
                <IconButton onClick={handleToggleSidebar} color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton>
                <Typography variant="h5">BookWellCare - Doctor Admin Panel</Typography>



            </header>


            <Outlet />
        </div>
    )
}

export default DoctorPanel;