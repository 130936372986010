// src/components/DoctorDashboard.js
import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {  Outlet, useNavigate } from 'react-router-dom';
import { SideBar } from '../SideBar/SideBar';
import { AppointmentsSection } from '../AppointmentsSection.js/AppointmentsSection';


const Appointments = () => {

  const navigate = useNavigate();

 


  


  useEffect(() => {
    
    if (!localStorage.getItem("email")) {
      navigate("/doctorLogin")
    }

    


  }, [])

  return (
    <div className="flex h-full ">
      {/* Sidebar */}
    

      {/* Main Content */}
      <div className="flex flex-col flex-grow ">
     
        

        {/* Main Content */}


       <AppointmentsSection />

        
      </div>


        

    </div>
  );
};

export default Appointments;
